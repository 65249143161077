@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@font-face {
    font-family: Bolota;
    src: url(../fonts/Bolota/Bolota-Variable.ttf);
}

// Colors
$primary: #006add;

// Fonts
$font-family-sans-serif: "Roboto";
// $headings-font-family: Bolota, sans-serif;

// Components
$border-radius: 8px;

$carousel-indicator-width: 10px;
$carousel-indicator-height: 10px;
$carousel-indicator-active-bg: #000000;
$carousel-dark-indicator-active-bg: #ffffff;
