.table-rounded {
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    margin: 0;

    tr:last-child td {
        border-bottom: none;
    }
}
