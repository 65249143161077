@import "./assets/styles/variables";

@import "~bootstrap/scss/bootstrap.scss";

html,
body {
    overscroll-behavior: none;
}

.huez-bg-gradient {
    background: linear-gradient(45deg, #463442, #006add);
}
