#main-nav {
    @media (min-width: 0) {
        bottom: 20px;
    }

    @media (min-width: 992px) {
        height: 300px;
        width: 300px;
        top: 75px;
    }
}
