.carousel {
    .carousel-indicators {
        position: absolute;
        bottom: -50px;
        button {
            // background-color: white !important;
            border-radius: 50%;
        }
    }
    .carousel-control-prev,
    .carousel-control-next {
        display: none;
    }
}
